import logo from "./logo.svg";
import "./App.css";

function App() {
  return (
    <div
      className="App"
      style={{ width: "100%", height: "100vh", background: "#c7d2fe" }}
    >
      <div className="topmenu">
        <div className="logo">
          <h1>Aug-9</h1>
        </div>
      </div>
      <div className="names">
        <h1>1.Lokesh PM</h1>
        <h1>2.Sanjay M</h1>
        <h1>3.Kumar M</h1>
        <h1>4.Selva K</h1>
        <h1>5.Navaneethan K</h1>
        <h1>6.senthur selvam M</h1>
        <h1>7.Mani K</h1>
        <h1>8.Logeshwaran S</h1>
      </div>
    </div>
  );
}

export default App;
